<template>
  <v-app>
    <DashboardCoreAppBar />
    <DashboardCoreDrawer />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import DashboardCoreAppBar from "./components/core/AppBar";
import DashboardCoreDrawer from "./components/core/Drawer";
import { mapState, mapGetters, mapActions } from "vuex";

const default_title =
  "We Are Locals | Foodiependent - Online Ordering Platform Admin Panel";

export default {
  name: "DashboardIndex",
  components: {
    DashboardCoreDrawer,
    DashboardCoreAppBar,
  },
  data: () => ({
    expandOnHover: false,
    after_first_load: false,
  }),

  computed: {
    ...mapState("BrandStore", ["brand"]),
    ...mapState("ShopStore", ["shop"]),
    ...mapState("AdminStore", ["in_house_orders", "takeaway_orders"]),
    ...mapGetters("AdminStore", [
      "has_retail_service",
      "has_dining_service",
      "dining_orders_length",
      "retail_orders_length",
      "sound_notification_file_name",
    ]),
    ...mapGetters("StaffStore", ["staff_accessible_paths"]),
    ...mapGetters("TableBookingStore", ["is_table_booking_enabled"]),
    in_house_orders_for_watcher() {
      return cloneDeep(this.in_house_orders);
    },
    takeaway_orders_for_watcher() {
      return cloneDeep(this.takeaway_orders);
    },
  },
  mounted() {
    if (!this.brand) {
      this.set_brand(this.shop.brands[0]);
    }
    if (this.shop && this.is_table_booking_enabled) {
      this.get_table_booking_data(this.shop.id);
    }
    this.update_page_title();
    if (this.$route.path !== "/") return;
    let redirect = "/settings";
    if (this.has_retail_service) redirect = "/retail";
    else if (this.has_dining_service) redirect = "/hospitality";
    if (!this.staff_accessible_paths.includes(redirect)) {
      redirect = this.staff_accessible_paths.filter((p) => p != "/")[0];
    }
    this.$router.push(redirect);
  },
  watch: {
    in_house_orders_for_watcher: function (new_orders, old_orders) {
      this.bell_ring(old_orders, new_orders);
    },
    takeaway_orders_for_watcher: function (new_orders, old_orders) {
      this.bell_ring(old_orders, new_orders);
    },
    retail_orders_length: function () {
      this.update_page_title();
    },
    dining_orders_length: function () {
      this.update_page_title();
    },
  },
  methods: {
    ...mapActions("BrandStore", ["set_brand"]),
    ...mapActions("TableBookingStore", ["get_table_booking_data"]),
    update_page_title() {
      let orders = this.dining_orders_length + this.retail_orders_length;
      let title = default_title;
      if (orders > 0) {
        title = `🔔 ${orders} New Order${
          orders > 1 ? "s" : ""
        } - ${default_title}`;
      }
      window.document.title = title;
    },
    bell_ring(old_orders, new_orders) {
      if (!this.after_first_load) {
        this.after_first_load = true;
        return;
      }
      if (new_orders.length > old_orders.length) {
        const sound_file = require(`@/assets/sounds/${this.sound_notification_file_name}.mp3`);
        const sound = new Audio(sound_file);
        sound.play();
      }
    },
  },
};
</script>
