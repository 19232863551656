<template>
  <v-container :class="container_class">
    <h3
      v-if="show_label"
      class="display-2 text--primary mb-4 text-center text-md-left"
    >
      {{ label }}
    </h3>
    <div
      class="d-flex"
      :class="{
        'flex-row flex-wrap': $vuetify.breakpoint.mdAndUp,
        'flex-column': $vuetify.breakpoint.smAndDown,
      }"
    >
      <!-- API -->
      <ServiceSwitch
        v-if="
          table_booking &&
          api_shop.table_booking &&
          api_shop.table_booking.is_enabled
        "
        :active.sync="table_booking_active"
        :label="SERVICE_TYPES_INFO.table_booking.pretty_name"
        :disabled="
          unsaved_settings || !Object.values(table_booking_state).every(Boolean)
        "
        :disabled_tooltip="table_booking_disabled_tooltip"
      />
      <!-- FIREBASE -->
      <ServiceSwitch
        v-if="in_house && firebase_shop.acquire_items.service.in_house"
        :active.sync="in_house_active"
        :label="SERVICE_TYPES_INFO.in_house.pretty_name"
      />
      <ServiceSwitch
        v-if="takeaway && firebase_shop.acquire_items.service.takeaway"
        :active.sync="takeaway_active"
        :label="SERVICE_TYPES_INFO.takeaway.pretty_name"
      />
      <ServiceSwitch
        v-if="collection && firebase_shop.acquire_items.service.collection"
        :active.sync="collection_active"
        :label="SERVICE_TYPES_INFO.collection.pretty_name"
      />
      <ServiceSwitch
        v-if="delivery && firebase_shop.acquire_items.service.delivery"
        :active.sync="delivery_active"
        :label="SERVICE_TYPES_INFO.delivery.pretty_name"
      />
      <ServiceSwitch
        v-if="
          hospitality_delivery &&
          firebase_shop.acquire_items.service.hospitality_delivery
        "
        :active.sync="hospitality_delivery_active"
        :label="SERVICE_TYPES_INFO.hospitality_delivery.pretty_name"
      />
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import firebase from "firebase/app";
import { db } from "@/db";
import { SERVICE_TYPES_INFO } from "@/shared/services";
import { upsert_service } from "@/requests";
import ServiceSwitch from "./ServiceSwitch.vue";

export default {
  name: "ActiveServices",
  components: {
    ServiceSwitch,
  },
  props: {
    show_label: { type: Boolean, deafult: true },
    container_class: { type: String, deafult: "pa-4 settings-box" },
    containerContentClass: { type: String, deafult: "" },
    label: { type: String, default: "Active Services" },
    collection: { type: Boolean, default: false },
    delivery: { type: Boolean, default: false },
    in_house: { type: Boolean, default: false },
    takeaway: { type: Boolean, default: false },
    table_booking: { type: Boolean, default: false },
    hospitality_delivery: { type: Boolean, default: false },
  },
  data() {
    return {
      SERVICE_TYPES_INFO,
    };
  },
  computed: {
    ...mapState("AdminStore", {
      firebase_shop: "shop",
      shop_id: "shop_id",
    }),
    ...mapState("ShopStore", {
      api_shop: "shop",
    }),
    ...mapState("TableBookingStore", ["unsaved_settings"]),
    ...mapGetters("AdminStore", ["can_accept_orders"]),
    ...mapGetters("TableBookingStore", ["table_booking_state"]),
    // API Services
    table_booking_active: {
      get() {
        return this.api_shop.table_booking?.is_active;
      },
      set(value) {
        this.update_active_api_service("table_booking", value);
      },
    },
    // FIREBASE Services
    collection_active: {
      get() {
        return this.firebase_shop.collection?.is_active;
      },
      set(value) {
        this.update_active_firebase_service("collection", value);
      },
    },
    delivery_active: {
      get() {
        return this.firebase_shop.delivery?.is_active;
      },
      set(value) {
        this.update_active_firebase_service("delivery", value);
      },
    },
    in_house_active: {
      get() {
        return this.firebase_shop.in_house?.is_active;
      },
      set(value) {
        this.update_active_firebase_service("in_house", value);
      },
    },
    takeaway_active: {
      get() {
        return this.firebase_shop.takeaway?.is_active;
      },
      set(value) {
        this.update_active_firebase_service("takeaway", value);
      },
    },
    hospitality_delivery_active: {
      get() {
        return this.firebase_shop.hospitality_delivery?.is_active;
      },
      set(value) {
        this.update_active_firebase_service("hospitality_delivery", value);
      },
    },
    table_booking_disabled_tooltip() {
      if (!Object.values(this.table_booking_state).every(Boolean)) {
        return "To activate this service, setup Table Booking Settings.";
      }
      if (this.unsaved_settings) {
        return "Save settings before activation.";
      }
      return "";
    },
  },
  methods: {
    async update_active_firebase_service(service, value) {
      const new_service_config = {
        ...this.firebase_shop[service],
        is_active: value,
      };
      try {
        await db
          .collection("shops")
          .doc(this.shop_id)
          .update({
            [service]: new_service_config,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        this.$emit("show_snackbar");
      } catch (error) {
        console.error("ActiveServices/update_Active_service ", error);
      }
    },
    async update_active_api_service(service, value) {
      const new_service = { ...this.api_shop[service], is_active: value };
      try {
        upsert_service(new_service);
        this.$emit("show_snackbar");
      } catch (error) {
        console.error("ActiveServices/update_Active_service ", error);
      }
    },
  },
};
</script>
