<template>
  <div v-if="breadcrumbs" class="d-flex">
    <div class="ml-2 breadcrumbs d-flex align-center white--text">
      <component
        :is="
          breadcrumb.to_name && breadcrumb.to_name !== $route.name
            ? 'router-link'
            : 'p'
        "
        class="breadcrumb mb-0"
        v-for="(breadcrumb, idx) in breadcrumbs"
        :key="`breadcrumb_${idx}`"
        :to="breadcrumb.to_name ? { name: breadcrumb.to_name } : null"
        >{{ breadcrumb_text(breadcrumb) }}</component
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Breadcrumbs",
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    ...mapState("AdminStore", ["shop"]),
    ...mapState("UserStore", ["is_community_admin"]),
    breadcrumbs() {
      return this.$route.meta.breadcrumb;
    },
  },
  methods: {
    breadcrumb_text(breadcrumb) {
      if (!breadcrumb) return "";
      if (breadcrumb.name === "__selected_brand_name__")
        return this?.selected_brand?.name ?? "Loading...";
      if (breadcrumb.name === "__selected_shop_name__")
        return this?.shop?.name ?? "Loading...";
      if (breadcrumb.name.includes("__brand_zone_label__"))
        return this.selected_brand
          ? breadcrumb.name.replace(
              "__brand_zone_label__",
              this.selected_brand.zone_label
            )
          : "Loading...";
      return breadcrumb.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  gap: 1rem;
  .breadcrumb {
    text-transform: capitalize;
    color: rgb(0, 0, 0) !important;
    text-decoration: none;
    font-size: 1.2rem;
    &:hover {
      color: var(--v-primary-base) !important;
    }
    &:last-child {
      font-weight: bold;
    }
    &:after {
      content: "/";
      color: rgb(0, 0, 0) !important;
      position: relative;
      font-size: 1rem;
      left: 7px;
    }
  }
  p.breadcrumb {
    &:last-of-type:after {
      content: none;
    }
    &:hover {
      color: black !important;
    }
  }
}
</style>
