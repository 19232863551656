<template>
  <v-app-bar id="app-bar" fixed app flat height="75">
    <v-btn class="mr-3" elevation="0" fab small @click="setDrawer(!drawer)">
      <v-icon
        class="text--primary"
        :class="{
          'rotate-180': drawer,
        }"
      >
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <Breadcrumbs v-if="$vuetify.breakpoint.mdAndUp" />
    <v-spacer />

    <div class="mx-3" />
    <div>
      <ActiveServices
        v-if="$vuetify.breakpoint.mdAndUp"
        :show_label="false"
        container_class="pt-1"
        in_house
        takeaway
        hospitality_delivery
        collection
        delivery
        table_booking
        @show_snackbar="snackbar = true"
      />
      <ActiveServicesMenu v-else @show-snackbar="snackbar = true" />
    </div>

    <v-btn
      class="ml-2"
      rounded
      elevation="0"
      color="primary"
      :href="to_single_brand_url()"
      target="_blank"
      v-if="shop.brands.length === 1"
    >
      Preview
    </v-btn>
    <v-menu v-else offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          elevation="0"
          color="primary"
          min-width="0"
          rounded
          v-bind="attrs"
          v-on="on"
        >
          Preview</v-btn
        >
      </template>
      <v-list>
        <v-list-item v-for="(brand, index) in shop.brands" :key="index">
          <a
            class="black--text ml-2 no-text-decoration"
            min-width="0"
            :href="to_brand_url(brand)"
            target="_blank"
          >
            as {{ brand.name }}
            <v-icon small class="ml-2">mdi-open-in-new</v-icon>
          </a>
        </v-list-item>
      </v-list>
    </v-menu>

    <Notifications />
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
    >
      <b class="black--text">Services updated</b>
      <template v-slot:action>
        <v-icon color="black" @click="snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import Notifications from "@/views/dashboard/components/core/Notifications";
import ActiveServices from "@/views/dashboard/components/shop_settings/ActiveServices";
import ActiveServicesMenu from "@/views/dashboard/components/shop_settings/ActiveServicesMenu";

import Breadcrumbs from "@/components/Breadcrumbs";

import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",
  components: {
    Breadcrumbs,
    Notifications,
    ActiveServices,
    ActiveServicesMenu,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapState("ShopStore", ["shop"]),
    ...mapState("AdminStore", ["shop_id"]),
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    to_single_brand_url() {
      return this.to_brand_url(this.shop.brands[0]);
    },
    to_brand_url(brand) {
      if (!brand) return;
      if (brand.brand_type === "single_shop") return brand.url;
      return `${brand.url}#/shop/${this.shop_id}`;
    },
  },
};
</script>
