<template>
  <div id="switch">
    <v-switch
      color="primary"
      v-model="synced_active"
      class="ml-4"
      hide-details
      :disabled="disabled"
    >
      <template v-slot:label>
        <span
          class="font-weight-bold"
          :class="{
            'accent--text': !synced_active,
          }"
        >
          {{ label }}
        </span>
      </template>
    </v-switch>
    <v-tooltip v-if="disabled && disabled_tooltip" bottom activator="#switch">
      <span>{{ disabled_tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ServiceSwitch",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      Default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabled_tooltip: {
      type: String,
      default: "",
    },
  },
  computed: {
    synced_active: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("update:active", value);
      },
    },
  },
};
</script>

<style></style>
