var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.container_class},[(_vm.show_label)?_c('h3',{staticClass:"display-2 text--primary mb-4 text-center text-md-left"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex",class:{
      'flex-row flex-wrap': _vm.$vuetify.breakpoint.mdAndUp,
      'flex-column': _vm.$vuetify.breakpoint.smAndDown,
    }},[(
        _vm.table_booking &&
        _vm.api_shop.table_booking &&
        _vm.api_shop.table_booking.is_enabled
      )?_c('ServiceSwitch',{attrs:{"active":_vm.table_booking_active,"label":_vm.SERVICE_TYPES_INFO.table_booking.pretty_name,"disabled":_vm.unsaved_settings || !Object.values(_vm.table_booking_state).every(Boolean),"disabled_tooltip":_vm.table_booking_disabled_tooltip},on:{"update:active":function($event){_vm.table_booking_active=$event}}}):_vm._e(),(_vm.in_house && _vm.firebase_shop.acquire_items.service.in_house)?_c('ServiceSwitch',{attrs:{"active":_vm.in_house_active,"label":_vm.SERVICE_TYPES_INFO.in_house.pretty_name},on:{"update:active":function($event){_vm.in_house_active=$event}}}):_vm._e(),(_vm.takeaway && _vm.firebase_shop.acquire_items.service.takeaway)?_c('ServiceSwitch',{attrs:{"active":_vm.takeaway_active,"label":_vm.SERVICE_TYPES_INFO.takeaway.pretty_name},on:{"update:active":function($event){_vm.takeaway_active=$event}}}):_vm._e(),(_vm.collection && _vm.firebase_shop.acquire_items.service.collection)?_c('ServiceSwitch',{attrs:{"active":_vm.collection_active,"label":_vm.SERVICE_TYPES_INFO.collection.pretty_name},on:{"update:active":function($event){_vm.collection_active=$event}}}):_vm._e(),(_vm.delivery && _vm.firebase_shop.acquire_items.service.delivery)?_c('ServiceSwitch',{attrs:{"active":_vm.delivery_active,"label":_vm.SERVICE_TYPES_INFO.delivery.pretty_name},on:{"update:active":function($event){_vm.delivery_active=$event}}}):_vm._e(),(
        _vm.hospitality_delivery &&
        _vm.firebase_shop.acquire_items.service.hospitality_delivery
      )?_c('ServiceSwitch',{attrs:{"active":_vm.hospitality_delivery_active,"label":_vm.SERVICE_TYPES_INFO.hospitality_delivery.pretty_name},on:{"update:active":function($event){_vm.hospitality_delivery_active=$event}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }