var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"value":_vm.$vuetify.breakpoint.smAndUp || _vm.drawer,"app":"","mini-variant":_vm.$vuetify.breakpoint.smAndUp && !_vm.drawer,"mini-variant-width":"66","fixed":"","floating":"","width":"260","mobile-breakpoint":"960","hide-overlay":_vm.$vuetify.breakpoint.smAndUp,"persistent":"","color":"secondary"},on:{"input":function (v) { return (_vm.drawer = v); }}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"secondary","contain":""}},[(_vm.shop && _vm.shop.logo)?_c('v-img',{attrs:{"src":_vm.shop.logo}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"display-1 font-weight-bold",domProps:{"textContent":_vm._s(_vm.shop && _vm.shop.name)}}),(_vm.is_directory_shop)?_c('v-list-item-subtitle',[_vm._v(" Directory Shop Only ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v("Connection Status "),_c('span',{class:{
              'primary--text': _vm.is_network_online,
              'red--text': !_vm.is_network_online,
            }},[_vm._v(" • "+_vm._s(_vm.is_network_online ? "Online" : "Offline"))])])],1)],1)],1),_c('v-list',{attrs:{"rounded":"","expand":"","nav":""}},[_vm._l((_vm.nav_items),function(el,idx){return [(!el.hidden)?[(!el.children)?_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:("root_el_" + idx),staticClass:"nav-el-item",attrs:{"dense":"","to":el.to,"active-class":"active-nav-el"}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(el.icon)}})],1),(_vm.display_titles)?_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(el.title)}}):_vm._e()],1):_c('v-list-group',{key:("root_el_" + idx),staticClass:"nav-el-item v-list-item--dense",attrs:{"dense":"","group":el.group},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-badge',{staticClass:"nav-badge",attrs:{"value":_vm.$vuetify.breakpoint.smAndUp && !_vm.drawer,"overlap":"","offset-x":"16px","offset-y":"12px","bottom":"","icon":"mdi-chevron-down"}},[_c('v-icon',{domProps:{"textContent":_vm._s(el.icon)}})],1)],1),(_vm.display_titles)?_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(el.title)}}):_vm._e()]},proxy:true}],null,true)},_vm._l((el.children),function(child_el,i){return _c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:i,staticClass:"nav-el-item",class:{
              'not-shown': child_el.hidden,
              'ml-7': _vm.drawer,
            },attrs:{"dense":"","active-class":"active-nav-el","to":child_el.to}},[_c('v-list-item-icon',{staticClass:"pl-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(child_el.icon)}})],1),(_vm.display_titles)?_c('v-list-item-title',[(child_el.badge)?_c('v-badge',{staticClass:"mt-0",attrs:{"dot":"","color":"accent","inline":""}},[_c('span',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(child_el.title)}})]):_c('span',{domProps:{"innerHTML":_vm._s(child_el.title)}})],1):_vm._e()],1)}),1)]:_vm._e()]})],2),_c('v-list',{staticClass:"other-drawer-items",attrs:{"nav":"","rounded":""}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"nav-el-item",class:{
        'not-shown': !_vm.staff_accessible_paths.includes('/settings'),
      },attrs:{"dense":"","to":"/settings","active-class":"active-nav-el"}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('$settings-cog')}})],1),_c('v-list-item-title',[_c('v-badge',{staticClass:"mt-0",attrs:{"value":!_vm.is_required_settings_complete,"dot":"","color":"accent","inline":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Settings")])])],1)],1),_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":"","top":"","content-class":"white br-16"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item',_vm._g({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"nav-el-item",attrs:{"dense":"","active-class":"active-nav-el"}},on),[_c('v-list-item-avatar',{attrs:{"contain":""}},[_c('Avatar',{attrs:{"variant":"beam","size":40,"colors":[
                '#FBEAC7',
                '#54C4C9',
                '#7ACB9F',
                '#A582C7',
                '#FF8080' ],"name":_vm.logged_in_staff ? _vm.logged_in_staff.name : _vm.user.displayName}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"test-h5"},[_vm._v(_vm._s(_vm.logged_in_staff ? _vm.logged_in_staff.name : _vm.user.displayName)+" "),_c('v-icon',{staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-chevron-up")])],1),_c('v-list-item-subtitle',{staticClass:"text-h6"},[_vm._v("Log Out")])],1)],1)]}}])},_vm._l((_vm.other_nav_items),function(el,idx){return _c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:("other_el_" + idx),staticClass:"nav-el-item",class:{
          'not-shown': el.hidden,
        },attrs:{"dense":"","to":el.to,"href":el.href,"active-class":"active-nav-el"}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(el.icon)}})],1),_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(el.title)}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }