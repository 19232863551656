<template>
  <v-dialog
    v-model="notifications_menu"
    width="500px"
    content-class="br-16 notifications"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-badge
          :value="notifications.length"
          color="accent"
          offset-x="8px"
          offset-y="20px"
        >
          <template v-slot:badge>
            <span>{{ notifications.length }}</span>
          </template>

          <v-icon>$bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-card-title
        class="primary white--text py-1 d-flex justify-space-between"
      >
        <div>
          <p class="mb-0 font-weight-medium">
            {{ notifications.length }} New Notification{{
              notifications.length > 1 ? "s" : ""
            }}
          </p>
        </div>
        <div class="">
          <v-btn
            small
            rounded
            color="#90d8dc"
            elevation="0"
            @click="dismiss_all_notifications"
            ><span class="white--text font-weight-bold no-text-transform"
              >Clear all</span
            >
          </v-btn>
          <v-icon style="transform: scale(0.8)" color="white">$bell</v-icon>
        </div>
      </v-card-title>
      <v-list
        three-line
        class="notifications-wrapper scroll-y"
        :tile="false"
        nav
      >
        <div v-if="notifications.length">
          <v-list-item
            v-for="(n, i) in notifications"
            :key="i"
            class="notification-item mx-2"
          >
            <v-list-item-icon>
              <v-icon>{{ get_notification_type_icon(n.type) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text--darken-2 py-1 mb-2">
              <div
                class="pa-1 br-8"
                :class="{ 'clickable pl-2': n.type == 'booking_cancellation' }"
                @click="goto_notification_booking(n)"
              >
                <p class="darkgrey--text text-body-2 font-weight-bold mb-1">
                  {{ format_notification_type(n.type) }}
                </p>
                <v-list-item-title class="notification-text pb-2">{{
                  n.text
                }}</v-list-item-title>
              </div>
              <v-list-item-subtitle
                class="d-flex justify-space-between text-h6"
              >
                <v-btn
                  elevation="0"
                  small
                  rounded
                  @click.stop="dismiss_notification(n)"
                  class="primary-alt"
                  ><span class="font-weight-bold" v-if="n.type === 'app_update'"
                    >Reload App Now</span
                  ><span class="font-weight-bold" v-else>Read</span></v-btn
                >
                <span class="align-self-center">{{
                  moment(n.created_at.toDate()).format("DD/MM/YYYY, HH:mm")
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-else>
          <p class="display-1 text-center mx-12 my-4">No new notifications</p>
        </div>
      </v-list>
      <div
        class="notification-close text-right"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <v-btn color="primary" rounded @click.stop="notifications_menu = false"
          >Close</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { db } from "@/db";

export default {
  name: "Notifications",
  data() {
    return {
      moment,
      notifications_menu: false,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "notifications"]),
  },
  watch: {
    notifications: function (value) {
      if (Array.isArray(value) && !value.length) {
        this.notifications_menu = false;
      }
    },
  },
  methods: {
    update_notification(notification_id, params) {
      db.collection("shops")
        .doc(this.shop_id)
        .collection("notifications")
        .doc(notification_id)
        .update(params);
    },
    dismiss_notification(notification) {
      this.update_notification(notification.id, {
        seen: true,
      });

      if (notification.type === "app_update") {
        setTimeout(() => {
          location.reload();
        }, 500);
      }
    },
    dismiss_all_notifications() {
      this.notifications.forEach((notification) => {
        this.dismiss_notification(notification);
      });
      this.notifications_menu = false;
    },
    format_notification_type(type) {
      const str = type.replace(/_/g, " ").toLowerCase().split(" ");
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].substring(1);
      }
      return str.join(" ");
    },
    get_notification_type_icon(type) {
      let icon;
      switch (type) {
        case "app_update":
          icon = "mdi-update";
          break;
        case "booking_cancellation":
          icon = "mdi-book-cancel-outline";
          break;
        default:
          icon = "mdi-bullhorn-outline";
          break;
      }
      return icon;
    },
    goto_notification_booking(notification) {
      if (!notification.booking_id) return;
      this.$router.push({
        path: "/management",
        query: { booking_id: notification.booking_id },
      });
      this.notifications_menu = false;
      this.update_notification(notification.id, { seen: true });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .notifications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: fixed;
    top: -10px;
    right: -10px;
  }

  .notifications-wrapper {
    flex-grow: 1;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 520px;

    .notifications-wrapper {
      // min-height: 20vh;
      max-height: 70vh;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    max-width: 100%;
  }

  .notification-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .clickable {
      cursor: pointer;
      transition: background-color 150ms linear;
      &:hover {
        background-color: var(--v-primary-lighten5);
      }
    }

    .notification-text {
      white-space: normal;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
