<template>
  <v-menu content-class="br-16" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn elevation="0" color="primary" v-on="on" rounded
        >Active services</v-btn
      >
    </template>
    <ActiveServices
      :show_label="false"
      container-content-class="br-16"
      container_class=" white pb-6"
      in_house
      takeaway
      hospitality_delivery
      collection
      delivery
      table_booking
      @show_snackbar="$emit('show-snackbar')"
    />
  </v-menu>
</template>

<script>
import ActiveServices from "@/views/dashboard/components/shop_settings/ActiveServices";

export default {
  name: "ActiveServicesMenu",
  components: {
    ActiveServices,
  },
};
</script>
